import { makeStyles } from "@material-ui/core"
import { SitePagesContext } from "@web-punks/core"
import { useWhenMobile } from "@web-punks/ui-mui"
import { navigate } from "gatsby"
import React from "react"
import { CustomDialog } from "../../ui/molecules/Dialogs"
import { SlidePageQuery_sanitySlide_data_contents_choices as ChoiceData } from "../../__generated__/SlidePageQuery"
import CardChoice from "./CardChoice"
import ChoiceDialogContent from "./ChoiceDialogContent"

interface Props {
  data: ChoiceData
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "50vw",
    [useWhenMobile(theme)]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))

const ChoiceController = ({ data }: Props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const pages = React.useContext(SitePagesContext)
  const directUrl = data.directLink?._id
    ? pages.getPagePath(data.directLink._id)
    : undefined

  const handleChoice = () => {
    if (directUrl) {
      navigate(directUrl)
      return
    }

    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      <CardChoice data={data} onClick={handleChoice} />
      <CustomDialog
        open={open}
        onClose={handleClose}
        fullScreen
        fullScreenMaxBreakpoint="sm"
        userClass={{
          container: classes.dialog,
        }}
      >
        <ChoiceDialogContent data={data} onClose={handleClose} />
      </CustomDialog>
    </>
  )
}

export default ChoiceController
