import { IconButton } from "@material-ui/core"
import { navigate } from "gatsby"
import React from "react"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

interface Props {
  icon?: React.ReactNode
  defaultPrevUrl?: string | (() => string)
}

export const BackButton = ({ defaultPrevUrl, icon }: Props) => {
  const handleBack = () => {
    if (window.history.length > 0) {
      window.history.back()
      return
    }

    if (defaultPrevUrl && typeof defaultPrevUrl === "string") {
      navigate(defaultPrevUrl)
      return
    }

    if (defaultPrevUrl && typeof defaultPrevUrl === "function") {
      navigate(defaultPrevUrl())
      return
    }

    navigate("/")
  }
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="home"
      onClick={handleBack}
    >
      {icon ?? <ArrowBackIcon />}
    </IconButton>
  )
}
