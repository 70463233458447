import React from "react"
import { graphql } from "gatsby"
import { Page } from "@web-punks/core"
import { withSanityPreview } from "@web-punks/contents-sanity"
import { SlidePageQuery } from "../__generated__/SlidePageQuery"
import SlideContainer from "../containers/slide"
import GameLayoutDataProvider from "../layouts/GameLayout/data"

interface Props {
  data: SlidePageQuery
}

const SlidePage = ({ data }: Props) => {
  return (
    <GameLayoutDataProvider>
      <Page id={data.sanitySlide?._id ?? undefined}>
        <SlideContainer contents={data.sanitySlide?.data?.contents as any} />
      </Page>
    </GameLayoutDataProvider>
  )
}

export default withSanityPreview(SlidePage)

export const pageQuery = graphql`
  query SlidePageQuery($id: String!) {
    sanitySlide(_id: { eq: $id }) {
      _id
      data {
        contents {
          game {
            data {
              layout {
                background {
                  asset {
                    url
                  }
                }
              }
            }
          }
          title
          _rawQuestion
          choices {
            button {
              _rawText
              image {
                asset {
                  url
                }
              }
              imageSize
            }
            directLink {
              _id
            }
            effect {
              _rawText
              image {
                asset {
                  url
                }
              }
              buttonLabel
              buttonLink {
                _id
              }
            }
          }
        }
      }
    }
  }
`
