import { makeStyles } from "@material-ui/core"
import { RichTextField } from "@web-punks/core"
import React from "react"
import CTA from "../../ui/atoms/CTA"
import { SlidePageQuery_sanitySlide_data_contents_choices as ChoiceData } from "../../__generated__/SlidePageQuery"

interface Props {
  data: ChoiceData
  onClose?: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    textAlign: "center",
    margin: "0 auto",
  },
  imageItem: {
    maxHeight: "45vh",
    maxWidth: "100%",
  },
  text: {
    marginTop: theme.spacing(2),
  },
  cta: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
}))

const ChoiceDialogContent = ({ data, onClose }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {data.effect?.image?.asset?.url && (
        <div className={classes.image}>
          <img
            src={data.effect?.image?.asset?.url}
            className={classes.imageItem}
          />
        </div>
      )}
      <RichTextField
        value={data.effect?._rawText}
        className={classes.text}
        variant="subtitle1"
        textCenter
      />

      <div className={classes.cta}>
        <CTA
          label={data.effect?.buttonLabel ?? ""}
          linkItemId={data.effect?.buttonLink?._id ?? undefined}
          action={
            data.effect?.buttonLink?._id === undefined ? onClose : undefined
          }
        />
      </div>
    </div>
  )
}

export default ChoiceDialogContent
