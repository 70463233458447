import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme, Grid, Typography } from "@material-ui/core"
import { BackFab, CloseFab } from "../../atoms/CustomFab"
// import { fontColors } from "../../../../themes/variables"

type ClosePosition = "left" | "right" | "none"
type CloseControl = "back" | "close"

interface Props {
  closePosition: ClosePosition
  closeControl: CloseControl
  children?: React.ReactNode
  onClose?: () => void
  px?: number
  py?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: ({ px }: any) => (px ? theme.spacing(px) : undefined),
    paddingRight: ({ px }: any) => (px ? theme.spacing(px) : undefined),
    paddingTop: ({ py }: any) => (py ? theme.spacing(py) : undefined),
    paddingBottom: ({ py }: any) => (py ? theme.spacing(py) : undefined),
  },
  closeColumn: {
    textAlign: ({ closePosition }: any) =>
      closePosition === "right" ? "right" : undefined,
  },
  close: {
    // color: fontColors.medium,
    margin: theme.spacing(0, 0.5),
  },
  title: {
    textAlign: "center",
  },
}))

export const CustomDialogHead = ({
  closePosition,
  children,
  onClose,
  closeControl,
  px,
  py,
}: Props) => {
  const classes = useStyles({
    closePosition,
    px,
    py,
  })
  return (
    <div className={classes.root}>
      <Grid
        container
        direction={closePosition === "right" ? "row-reverse" : "row"}
        alignItems="center"
      >
        <Grid item xs={2} className={classes.closeColumn}>
          {closeControl === "close" ? (
            <CloseFab
              color="transparent"
              onClick={onClose}
              size="small"
              className={classes.close}
            />
          ) : (
            <BackFab
              color="transparent"
              onClick={onClose}
              size="small"
              className={classes.close}
            />
          )}
        </Grid>
        <Grid item xs={8} className={classes.title}>
          <Typography variant="subtitle1">{children}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

CustomDialogHead.defaultProps = {
  closePosition: "right",
  closeControl: "close",
}
