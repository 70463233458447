import { Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { SlidePageQuery_sanitySlide_data_contents as PageContent } from "../../__generated__/SlidePageQuery"
import CenteredBox from "../../ui/spacings/CenteredBox"
import {
  CustomTypography,
  useWhenDesktop,
  useWhenMobile,
} from "@web-punks/ui-mui"
import { RichTextField } from "@web-punks/core"
import HomeIcon from "@material-ui/icons/Home"
import ChoiceController from "./ChoiceController"
import { FadeIn } from "../../ui/animations"
import DefaultLayout from "../../layouts/DefaultLayout"
import { getGameBackground } from "../../converters/contents"
import { CenteredText } from "../../ui/spacings/AlignedText"
import CustomContainer from "../../ui/spacings/CustomContainer"
import { BackButton } from "../../components/navigation/BackButton"
import { Link } from "gatsby"

const useStyles = makeStyles((theme) => ({
  container: {
    [useWhenMobile(theme)]: {
      paddingBottom: theme.spacing(4),
    },
  },
  question: {
    marginTop: theme.spacing(4),
  },
  choices: {
    [useWhenDesktop(theme)]: {
      marginTop: theme.spacing(8),
    },
  },
  choice: {
    [useWhenDesktop(theme)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [useWhenMobile(theme)]: {
      marginTop: theme.spacing(3),
    },
  },
  logo: {
    maxHeight: "46px",
  },
}))

interface Props {
  contents: PageContent
}

const SlideContainer = ({ contents }: Props) => {
  const classes = useStyles()
  return (
    <DefaultLayout
      background={
        contents.game?.data ? getGameBackground(contents.game.data) : undefined
      }
      header={{
        rightControl: (
          <Link to="/horse-game">
            <HomeIcon />
            {/* <Logo className={classes.logo} /> */}
          </Link>
        ),
        leftControl: <BackButton defaultPrevUrl="/horse-game" />,
      }}
      noFooter
    >
      <CustomContainer className={classes.container}>
        <CenteredBox>
          <FadeIn>
            <Grid container justify="center">
              <Grid item xs={12} sm={8}>
                <CenteredText>
                  <CustomTypography variant="h3" color="primary">
                    {contents.title}
                  </CustomTypography>
                  <RichTextField
                    value={contents._rawQuestion}
                    className={classes.question}
                    variant="subtitle1"
                  />
                </CenteredText>
              </Grid>
            </Grid>
          </FadeIn>
          <FadeIn delay={500} w100>
            <Grid container justify="center" className={classes.choices}>
              {contents.choices?.map((choice, index) => (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  key={index}
                  className={classes.choice}
                >
                  <ChoiceController data={choice as any} />
                </Grid>
              ))}
            </Grid>
          </FadeIn>
        </CenteredBox>
      </CustomContainer>
    </DefaultLayout>
  )
}

export default SlideContainer
