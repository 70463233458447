import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  makeStyles,
} from "@material-ui/core"
import { RichTextField } from "@web-punks/core"
import React from "react"
import { SlidePageQuery_sanitySlide_data_contents_choices as ChoiceData } from "../../__generated__/SlidePageQuery"

interface Props {
  data: ChoiceData
  onClick: () => void
}

const useStyles = makeStyles((theme) => ({
  text: {
    paddingRight: theme.spacing(2),
  },
  media: {
    height: 280,
    backgroundSize: ({ backgroundSize }: any) => backgroundSize,
  },
}))

const CardChoice = ({ data, onClick }: Props) => {
  const classes = useStyles({
    backgroundSize: data.button?.imageSize,
  })
  return (
    <Card onClick={onClick}>
      <CardActionArea>
        {data.button?.image?.asset?.url && (
          <CardMedia
            className={classes.media}
            image={data.button?.image?.asset?.url ?? ""}
          />
        )}
        <CardContent>
          <RichTextField
            value={data.button?._rawText}
            textCenter
            variant="h6"
            className={classes.text}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CardChoice
